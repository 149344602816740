import React from "react"
import * as S from "./custom-html.styles.jsx"

const CustomHtml = ({ customHtml }) => {
  if (!customHtml) return null
  return (
    <S.Wrapper maxWidth={false}>
      <div dangerouslySetInnerHTML={{ __html: customHtml }}></div>
    </S.Wrapper>
  )
}
export default CustomHtml
