import React from "react"
import InnerHTML from "dangerously-set-html-content"
import * as S from "./store-locator-map.styles.jsx"

const StoreLocatorMap = data => {
  const inlineScript = `
        <script>
        (() => { 
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.async = !0;
            a.src = "https://cdn.storepoint.co/api/v1/js/1612e496b5e59f.js";
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);
        })();
        </script>
    `
  const aisleScript = `
    <script> window.aisleFormConfigurations = window.aisleFormConfigurations || {}; window.aisleFormConfigurations['cm1i9u70p0001jpvok4vk4oye'] = { widgetId: 'cm1i9u70p0001jpvok4vk4oye', rootTargetElement: 'aisle-form-display__cm1i9u70p0001jpvok4vk4oye' }; </script> <script defer src="https://cdn.gotoaisle.com/embed-forms/script/loader/embedFormLoader.js" onload="window.aisleLoader.loadAssets(window.aisleFormConfigurations['cm1i9u70p0001jpvok4vk4oye'])" ></script>
    `
  return (
    <S.Wrapper scrollSnap={data.scrollSnap}>
      <div id="aisle-form-display__cm1i9u70p0001jpvok4vk4oye"></div>
      <InnerHTML html={aisleScript} />
      <div id="storepoint-container" data-map-id="1612e496b5e59f"></div>
      <InnerHTML html={inlineScript} />
    </S.Wrapper>
  )
}
export default StoreLocatorMap
