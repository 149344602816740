import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;

  ${props =>
    props.scrollSnap
      ? `
          scroll-snap-align: start;
          scroll-snap-stop: always;
          height: 100vh;
          
          `
      : ""}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    ${props =>
      props.scrollSnap
        ? `
                padding-top: 5rem;
                
                `
        : ""}
  }

  #storepoint-search-bar-container {
    width: 100%;
    max-width: 350px;
  }

  #storepoint-container #storepoint-search {
    background-color: #79b6f5;
    border: 0 none;
    text-align: center;

    .mapboxgl-ctrl-geocoder {
      width: 100%;
    }

    .mapboxgl-ctrl-geocoder input[type="text"],
    #storepoint-searchbar,
    .storepoint-dropdown {
      border: 2px solid #1e1499;
      border-radius: 0;
      font-size: ${({ theme }) => theme.typography.pxToRem(17)};
      font-family: ${({ theme }) => theme.fonts.tertiary};
      color: #1f1497;

      &:hover {
        border: 2px solid #1e1499;
      }
    }
  }

  #storepoint-panel {
    font-family: ${({ theme }) => theme.fonts.tertiary};
    padding: 0 15px;
  }

  svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
    top: 12px;
  }
`
